import React from "react";
import Layout from "../../containers/Layout";
import { Contact } from "../../components";
import FaqGeneral from "../../components/FaqsComponents/FaqGeneral";

export default function General() {
  return (
    <Layout title={"FAQs"}>
      <FaqGeneral />
      <Contact />
    </Layout>
  );
}
